@import url("https://fonts.googleapis.com/css2?family=Anton&family=Archivo+Black&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500;1,600&family=Source+Sans+Pro:wght@400;600;700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Anton", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
  background-image: url("images/heroBackground.svg");
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* HHandle On Hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  color: #fff;
}
a:hover {
  color: #ffb328;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}
